import React from 'react';
import './UrlSearch.css';

const protocolPrepend = (e) => {
  const { target } = e;
  if (target.value === '') {
    target.value = 'https://';
    target.setSelectionRange(target.value.length, target.length);
  }
}

const clearProtocol = (e) => {
  const { target } = e;
  if (target.value === 'https://') {
    target.value = '';
  }
}

const UrlSearch = ({ updateQuery, performSearch }) => {
  return (
    <div className='search'>
      <input type='text'
        onFocus={protocolPrepend}
        onBlur={clearProtocol}
        onKeyUp={updateQuery}
        placeholder='Enter a website address'
      />
      <button onClick={performSearch}>Get Palette</button>
    </div>
  );
};

export default UrlSearch;