import React from 'react';
import MainSwatch from './MainSwatch';

const MainColors = ({ colors }) => {
  return (
    <ul>
      {colors.map(color => <li key={color[0]}><MainSwatch color={color[0]}></MainSwatch></li>)}
    </ul>
  );
};

export default MainColors;