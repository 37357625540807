import React from 'react';
import drops from '../drops2.svg';
import FileUploader from './FileUploader';
import UrlSearch from './UrlSearch';
import NavTabs from './NavTabs';
import './Header.css';

const Header = props => {
  const { updateQuery, performSearch, minimized, activeTab, onTabChange, processImageData, imgSrc } = props;

  const paletteGetter = activeTab === 'image' ?
    <div>
      <FileUploader onUpload={processImageData} />
      {imgSrc ?
        <div className='imgPreview'><p>Upload Preview:</p><img src={imgSrc} alt='preview' className='previewImg'></img></div> : null}
    </div>

    :
    <UrlSearch updateQuery={updateQuery} performSearch={performSearch} />;

  return (
    <div className={minimized ? 'searchSection minimized' : 'searchSection'}>
      <div className='logo'>
        <img src={drops} className="drops" alt="drops" style={{ height: '40px' }} />
        <a href='/'><h1>Color Fetch</h1></a>
      </div>
      <p>Automatically extract and generate a color palette from any website or image</p>
      <NavTabs
        activeTab={activeTab}
        onChange={onTabChange}
      />
      {paletteGetter}
    </div>
  );
};

export default Header;