import React from 'react';
import './NavTabs.css';

const NavTabs = ({ activeTab, onChange }) => {
  const handleClick = (e) => {
    if (e.target.id === 'url') {
      onChange('url');
    } else if (e.target.id === 'image') {
      onChange('image');
    }
  }

  return (
    <div className='nav'>
      <ul>
        <li id='url' onClick={handleClick} className={activeTab === 'url' ? 'active' : ''}><h2>From Website</h2></li>
        <li id='image' onClick={handleClick} className={activeTab === 'image' ? 'active' : ''}><h2>From Upload</h2></li>
      </ul>
    </div>
  );
};

export default NavTabs;