import React, { Component } from 'react';
import MainColors from './MainColors';
import SelectionPreview from './SelectionPreview';
import './MainResult.css';

class MainResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: '',
    };
    this.getMainColors.bind(this);
  }
  getMainColors = ({ bgcolors, colors, image }) => {
    const allColors = []

    if (bgcolors) {
      Object.keys(bgcolors).forEach(color => {
        if (color !== 'rgba(0, 0, 0, 0)' && color !== 'rgb(0, 0, 0)' && color !== 'rgb(255, 255, 255)' && color !== 'rgb(0, 0, 238)') {
          allColors.push([color, bgcolors[color]]);
        }
      });
    }

    if (colors) {
      Object.keys(colors).forEach(color => {
        if (color !== 'rgba(0, 0, 0, 0)' && color !== 'rgb(0, 0, 0)' && color !== 'rgb(255, 255, 255)' && color !== 'rgb(0, 0, 238)') {
          allColors.push([color, colors[color]]);
        }
      });
    }

    if (image) {
      image.forEach(color => {
        if (color !== 'rgba(0, 0, 0, 0)' && color !== 'rgb(0, 0, 0)' && color !== 'rgb(255, 255, 255)' && color !== 'rgb(0, 0, 238)') {
          allColors.push([color, 1]);
        }
      });
    }

    const colorsSorted = allColors.sort(function (a, b) { return b[1] - a[1] });
    return colorsSorted.slice(0, 6);
  }
  render() {
    const mainColors = this.getMainColors(this.props.data);
    return (
      <div className='mainResults'>
        <MainColors colors={mainColors} />
        <SelectionPreview colors={mainColors} />
      </div >
    );
  }
}

export default MainResult;