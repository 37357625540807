import React from 'react';
import * as rgb2hex from 'rgb-hex';
import './PaletteSwatch.css';

const PaletteSwatch = props => {
  const { color, count } = props;
  console.log('paletteswatch: ', color);
  const swatchRgb = color;
  const swatchHex = rgb2hex(color);
  const showDetails = false;

  const details = showDetails ? (
    <div><p>{`#${swatchHex}`}</p>
      <div className='colorFrequency'>{count}</div>
    </div>
  ) :
    null;

  return (
    <div className='colorSwatch'
      style={{ backgroundColor: `#${swatchHex}` }}
      key={swatchHex}
      data-rgb={swatchRgb}
      data-hex={swatchHex}
    >
      {details}
    </div>
  );
};

export default PaletteSwatch;