import copy from 'clipboard-copy';
import './CopyButton.css';
import React, { Component } from 'react';

class CopyButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    }
  }

  onCopy = (dataToCopy) => {
    this.setState({ clicked: true });
    copy(dataToCopy);
  }

  render() {
    const { dataToCopy, label } = this.props;
    return (
      <div className='copyToClipboard'>
        <button
          onClick={() => this.onCopy(dataToCopy)}
          className={this.state.clicked ? 'copyButton copied' : 'copyButton'}
        >{this.state.clicked ? 'Copied ✔' : label}</button>
      </div>
    );
  }
}

CopyButton.propTypes = {

};

export default CopyButton;