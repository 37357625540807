import React from 'react';
import Loader from './Loader';
import './ResultsSection.css';
import dropper from '../dropper.svg';
import ResultType from './ResultType';
import MainResult from './MainResult';

const ResultsSection = props => {
  const { results, url, loading, error, errorMsg } = props;
  console.log({ results });
  if (error) {
    return <div className='results'>
      <p>
        {errorMsg}
      </p>
    </div>
  }

  const section = url ?
    <div className='results'>
      <p>{`Color palette for:  `}<a href={url}>{url}</a></p>
      <MainResult title={'Main'} data={results} />
      <ResultType title={'Image'} data={results.image} />
      <ResultType title={'Background'} data={results.bgcolors} />
      <ResultType title={'Text'} data={results.colors} />
    </div >
    :
    <div className='results'>
      <img src={dropper} alt='results' style={{ height: '32px' }}></img>
      <p>
        Enter a website address above and the color palettes will show here.
        </p>
    </div>;

  return loading ? <div className='results'>Extracting colors...<Loader /></div> : section;
};

export default ResultsSection;