import React, { Component } from 'react';
import rgb2hex from 'rgb-hex';
import CopyButton from './CopyButton';
import './MainSwatch.css';

class MainSwatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    };
    this.onHover.bind(this);
    this.onLeave.bind(this);
  }

  onHover = () => {
    this.setState({ hovered: true });
  }

  onLeave = () => {
    this.setState({ hovered: false });
  }

  render() {
    const { color } = this.props;

    return (
      <div className='mainSwatch' onMouseEnter={this.onHover} onMouseLeave={this.onLeave}>
        <div className='mainColorSquare' style={{ background: `${color}` }}></div>
        <div className='content'>#{rgb2hex(color)}</div>
        <CopyButton dataToCopy={`${color}`} label='Copy' />
      </div>
    );
  };
};

export default MainSwatch;