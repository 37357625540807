import React, { Component } from 'react';
import Header from './Header'
import ResultsSection from './ResultsSection'
import isUrl from 'is-url-superb';

const basePaletteFetcherUrl = 'https://zemcrw9w79.execute-api.us-east-1.amazonaws.com/fetch/';

class PaletteFetcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paletteData: [],
      queryUrl: '',
      paletteUrl: '',
      loading: false,
      minimized: false,
      error: false,
      errorMsg: '',
      activeTab: 'url'
    };
  }

  requestPalette = (url) => {
    this.setState({ loading: true, minimized: true, error: false, errorMsg: '' });
    const fetchQuery = this.state.queryUrl;
    if (isUrl(fetchQuery)) {
      this.fetchUrl(fetchQuery)
    } else {
      this.handleError('Please enter a valid URL!')
    }

  }

  fetchUrl = (fetchQuery) => {
    fetch(`${basePaletteFetcherUrl}?url=${encodeURIComponent(fetchQuery)}`, { mode: "cors" })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          paletteUrl: fetchQuery,
          paletteData: data,
          loading: false
        });
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error);
        this.handleError('Uh oh, there was an issue finding this URL!');
      });
  }

  processImageData = (colors, imgSrc) => {
    this.setState({
      paletteUrl: 'image',
      imgSrc: imgSrc,
      paletteData: { 'image': colors },
      loading: false
    });
  }

  updateQuery = (e) => {
    const query = e.target.value;
    this.setState({ queryUrl: query });
    if (e.keyCode === 13) {
      this.requestPalette(this.state.queryUrl)
    }
  }

  handleError = (error) => {
    this.setState({
      paletteData: [],
      queryUrl: '',
      paletteUrl: '',
      loading: false,
      error: true,
      errorMsg: error
    })
  }

  onTabChange = (val) => {
    this.setState({ activeTab: val });
  }

  render() {
    return (
      <div className='main'>
        <Header
          performSearch={this.requestPalette}
          updateQuery={this.updateQuery}
          minimized={this.state.minimized}
          activeTab={this.state.activeTab}
          onTabChange={this.onTabChange}
          processImageData={this.processImageData}
          imgSrc={this.state.imgSrc}
        />
        <ResultsSection
          url={this.state.paletteUrl}
          results={this.state.paletteData}
          loading={this.state.loading}
          error={this.state.error}
          errorMsg={this.state.errorMsg}
        />
      </div>
    );
  }
}

export default PaletteFetcher;