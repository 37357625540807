import React from 'react';
import PaletteSwatch from './PaletteSwatch';
import CopyButton from './CopyButton';
import './ResultType.css';

const ResultType = ({ title, data }) => {
  if (!data) {
    return null;
  }
  const dataIterator = title === 'Image' ? data : Object.keys(data);
  return (
    <div className='resultType'>
      <div className='title'>
        <h2>{title} {`(${Object.keys(data).length})`}</h2>
        <CopyButton dataToCopy={Object.keys(data).join()} label='Copy Palette' />
      </div>
      {dataIterator.map(result => {
        return <PaletteSwatch key={result} color={result} count={data[result]} />;
      })}
    </div>
  );
};

export default ResultType;