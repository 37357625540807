import addImg from '../addImg.svg'
import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
const getColors = require('get-image-colors');

export default class FileUploader extends React.Component {
  onDrop = (acceptedFile, rejectedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      const file = reader.result;
      const buffer = Buffer.from(file);
      const imgDataSrc = `data:${acceptedFile[0].type};base64, ${buffer.toString('base64')}`;

      getColors(buffer, acceptedFile[0].type).then(colors => {
        const returnColors = colors.map(color => color.css())
        this.props.onUpload(returnColors, imgDataSrc);
      })
    };
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsArrayBuffer(acceptedFile[0]);
  }

  render() {
    return (
      <Dropzone
        onDrop={this.onDrop}
        accept="image/jpeg, image/png"
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
            >
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop files here...</p> :
                  <div>
                    <img src={addImg} alt='upload'></img>
                    <p>Drag and drop or click to select image</p>
                  </div>
              }
            </div>
          )
        }}
      </Dropzone>
    );
  }
}