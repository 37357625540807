import React from 'react';
import './SelectionPreview.css';

const SelectionPreview = ({ colors }) => {
  const defaultColor = '#333';
  const first = colors[0] ? colors[0][0] : defaultColor;
  const second = colors[1] ? colors[1][0] : defaultColor;
  const third = colors[2] ? colors[2][0] : defaultColor;
  const thirdLeast = colors[3] ? colors[3][0] : defaultColor;
  const secondLeast = colors[4] ? colors[4][0] : defaultColor;
  const least = colors[5] ? colors[5][0] : defaultColor;

  return (
    <div className='selectionPreview' style={{ background: secondLeast }}>
      <div className='previewBox'>
        <p className='previewTitle' style={{ color: least }}>Preview</p>
        <h1 className='previewHeader' style={{ color: second }}>Use what you see, don't plan it.</h1>
        <hr style={{ borderColor: second }}></hr>
        <p style={{ color: first }}>In your imagination you can go anywhere you want. Now then, let's play. And I will hypnotize that just a little bit. We want to use a lot pressure while using no pressure at all. Just go back and put one little more happy tree in there.</p>
        <p style={{ color: first }}>We don't make mistakes we just have happy little accidents. Now, we're going to fluff this cloud. Trees grow however makes them happy. This present moment is perfect simply due to the fact you're experiencing it.</p>
        <button className='primaryButton' style={{ background: thirdLeast, color: `#fff` }}>Main Action</button>
        <button className='secondaryButton' style={{ color: third, borderColor: third }}>Secondary Action</button>
      </div>
    </div>
  );
};

export default SelectionPreview;